import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import tracking from 'src/tracking';
import { Recipe, RecipeSevenItemsBlock as Data, Tag } from '../../../graphql/generated/api-graphql';
import CardsBlock, { CardsBlockProps } from '../../../components/molecules/CardsBlock';
import { transformRecipeCard } from '../../RecipeCard/transformRecipeCard';
import { transformRecipeBigCard } from '../../RecipeCard/transformRecipeBigCard';
import { transformTagForCloud } from '../../Common/tag';
import { Icons } from '../../../components/atoms/Icon';
import { BlockProps } from '../props';
import Router, { routes } from '../../../routes/Router';
import { SearchType } from '../../SearchEngine/searchType';
export type RecipeSevenItemsBlockProps = Omit<BlockProps, 'data'> & {
  data: Data;
};
const RecipeSevenItemsBlock: FC<RecipeSevenItemsBlockProps> = ({
  data,
  template
}) => {
  const {
    t
  } = useTranslation();
  const props: CardsBlockProps = {
    title: data?.title ?? '',
    tags: data?.tags?.map((tag: Tag) => transformTagForCloud(tag, `${template} - ${data.title}`)),
    highlighted: transformRecipeBigCard(t, data?.recipes?.[0], data?.title ?? undefined),
    cards: data?.recipes?.slice(1)?.map((recipe: Recipe) => transformRecipeCard(t, recipe, data?.title ?? undefined)),
    actionButtonProps: {
      href: Router.getRouteUrl(routes.searchType, {
        type: SearchType.Recipe
      }),
      onClick: () => tracking.cta(t('see_more_recipes'), data?.title ?? undefined),
      label: t('see_more_recipes'),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight
      }
    }
  };
  return <CardsBlock {...props} />;
};
export default RecipeSevenItemsBlock;